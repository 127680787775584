import { JOBS_REDUCER_ACTIONS } from "../../constants/constants";

const INITIAL_STATE = {
  jobs: [],
  loading: false,
  error: null,
};

const jobsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case JOBS_REDUCER_ACTIONS.SET_JOBS:
      return {
        ...state,
        jobs: action.payload,
      };
    case JOBS_REDUCER_ACTIONS.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case JOBS_REDUCER_ACTIONS.SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default jobsReducer;
