import React from "react";

import CiscoColor from "../../../../assets/Clients/cisco_color.svg";
import CiscoGrey from "../../../../assets/Clients/cisco_grey.svg";
import HpIndingoColor from "../../../../assets/Clients/hpIndingo_color.svg";
import HpIndingoGrey from "../../../../assets/Clients/hpIndingo_grey.svg";
import NokiaColor from "../../../../assets/Clients/nokia_color.svg";
import NokiaGrey from "../../../../assets/Clients/nokia_grey.svg";
import RuckusColor from "../../../../assets/Clients/ruckus_color.svg";
import RuckusGrey from "../../../../assets/Clients/ruckus_grey.svg";
import QwiltColor from "../../../../assets/Clients/qwilt_color.svg";
import QwiltGrey from "../../../../assets/Clients/qwilt_grey.svg";
import BroadsayColor from "../../../../assets/Clients/broadsay_color.svg";
import BroadsayGrey from "../../../../assets/Clients/broadsay_grey.svg";
import CatoColor from "../../../../assets/Clients/cato_color.svg";
import CatoGrey from "../../../../assets/Clients/cato_grey.svg";
import OfferDropColor from "../../../../assets/Clients/offerDrop_color.svg";
import OfferDropGrey from "../../../../assets/Clients/offerDrop_grey.svg";
import GenesysColor from "../../../../assets/Clients/genesys_color.svg";
import GenesysGrey from "../../../../assets/Clients/genesys_grey.svg";
import AlcatelColor from "../../../../assets/Clients/alcatel_color.svg";
import AlcatelGrey from "../../../../assets/Clients/alcatel_grey.svg";
import AlphaOmegaGrey from "../../../../assets/Clients/alphaomega_grey.svg";
import AlphaOmegaColor from "../../../../assets/Clients/alphaomega_color.svg";
import TwistGrey from "../../../../assets/Clients/twist_grey.svg";
import TwistColor from "../../../../assets/Clients/twist_color.svg";
import KlaGrey from "../../../../assets/Clients/kla_grey.svg";
import KlaColor from "../../../../assets/Clients/kla_color.svg";
import HighconGrey from "../../../../assets/Clients/highcon_grey.svg";
import HighconColor from "../../../../assets/Clients/highcon_color.svg";
import AgadoGrey from "../../../../assets/Clients/agado_grey.svg";
import AgadoColor from "../../../../assets/Clients/agado_color.svg";
import PeaxyGrey from "../../../../assets/Clients/peaxy_grey.svg";
import PeaxyColor from "../../../../assets/Clients/peaxy_color.svg";
import NutanizGrey from "../../../../assets/Clients/nutaniz_grey.svg";
import NutanizColor from "../../../../assets/Clients/nutaniz_color.svg";
import PowtoonGrey from "../../../../assets/Clients/powtoon_grey.svg";
import PowtoonColor from "../../../../assets/Clients/powtoon_color.svg";
import RiskifiedGrey from "../../../../assets/Clients/riskified_grey.svg";
import RiskifiedColor from "../../../../assets/Clients/riskified_color.svg";
import BabyarkGrey from "../../../../assets/Clients/babyark_grey.svg";
import BabyarkColor from "../../../../assets/Clients/babyark_color.svg";

import ClientLogoComponent from "./ClientLogo/ClientLogo";

import Section from "../../../../components/Section/Section";

import "./Clients.css";

function ClientsComponent() {
  return (
    <Section title="Our Clients" customStyle="Clients">
      <div className="clients-outer-div">
        <div className="clients-inner-div">
          <ClientLogoComponent
            src={CiscoGrey}
            hover={CiscoColor}
            alt="Cisco"
            aos="flip-up"
          />
          <ClientLogoComponent
            src={HpIndingoGrey}
            hover={HpIndingoColor}
            alt="Hp Indingo"
            aos="flip-down"
          />
        </div>
        <div className="clients-inner-div">
          <ClientLogoComponent
            src={NokiaGrey}
            hover={NokiaColor}
            alt="Nokia"
            aos="flip-left"
          />
          <ClientLogoComponent
            src={RuckusGrey}
            hover={RuckusColor}
            alt="Ruckus"
            aos="flip-up"
          />
        </div>
      </div>
      <div className="clients-outer-div">
        <div className="clients-inner-div">
          <ClientLogoComponent
            src={KlaGrey}
            hover={KlaColor}
            alt="KLA"
            aos="flip-down"
          />
          <ClientLogoComponent
            src={QwiltGrey}
            hover={QwiltColor}
            alt="Qwilt"
            aos="flip-up"
          />
        </div>
        <div className="clients-inner-div">
          <ClientLogoComponent
            src={BroadsayGrey}
            hover={BroadsayColor}
            alt="Broadsay"
            aos="flip-down"
          />
          <ClientLogoComponent
            src={CatoGrey}
            hover={CatoColor}
            alt="Cato"
            aos="flip-left"
          />
        </div>
      </div>
      <div className="clients-outer-div">
        <div className="clients-inner-div">
          <ClientLogoComponent
            src={OfferDropGrey}
            hover={OfferDropColor}
            alt="OfferDrop"
            aos="flip-down"
          />
          <ClientLogoComponent
            src={GenesysGrey}
            hover={GenesysColor}
            alt="Genesys"
            aos="flip-left"
          />
        </div>
        <div className="clients-inner-div">
          <ClientLogoComponent
            src={AlcatelGrey}
            hover={AlcatelColor}
            alt="Alcatel"
            aos="flip-down"
          />
          <ClientLogoComponent
            src={AlphaOmegaGrey}
            hover={AlphaOmegaColor}
            alt="AlphaOmega"
            aos="flip-down"
          />
        </div>
      </div>
      <div className="clients-outer-div">
        <div className="clients-inner-div">
          <ClientLogoComponent
            src={TwistGrey}
            hover={TwistColor}
            alt="Twist"
            aos="flip-down"
          />
          <ClientLogoComponent
            src={BabyarkGrey}
            hover={BabyarkColor}
            alt="Babyark"
            aos="flip-down"
          />
        </div>
        <div className="clients-inner-div">
          <ClientLogoComponent
            src={HighconGrey}
            hover={HighconColor}
            alt="Highcon"
            aos="flip-down"
          />
          <ClientLogoComponent
            src={AgadoGrey}
            hover={AgadoColor}
            alt="Agado"
            aos="flip-up"
          />
        </div>
      </div>
      <div className="clients-outer-div">
        <div className="clients-inner-div">
          <ClientLogoComponent
            src={PeaxyGrey}
            hover={PeaxyColor}
            alt="Peaxy"
            aos="flip-right"
          />
          <ClientLogoComponent
            src={NutanizGrey}
            hover={NutanizColor}
            alt="Nutaniz"
            aos="flip-down"
          />
        </div>
        <div className="clients-inner-div">
          <ClientLogoComponent
            src={PowtoonGrey}
            hover={PowtoonColor}
            alt="Powtoon"
            aos="flip-left"
          />
          <ClientLogoComponent
            src={RiskifiedGrey}
            hover={RiskifiedColor}
            alt="Riskified"
            aos="flip-up"
          />
        </div>
      </div>
    </Section>
  );
}

export default ClientsComponent;
