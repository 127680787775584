import React from "react";
import { useTranslation } from "react-i18next";
import Section from "../../../../components/Section/Section";
import { ABOUT_US_DETAILS } from "../../../../constants/constants";
import "./Details.css";

const DetailsItem = ({ icon, title, description }) => {
  const { t } = useTranslation();
  return (
    <div className="details-item-container">
      {icon}
      <div className="details-item-text">
        <div className="details-item-title">
          <span tabIndex={0}>{t(title)}</span>
        </div>
        <div className="details-item-description">
          <span tabIndex={0}>{t(description)}</span>
        </div>
      </div>
    </div>
  );
};

const Details = () => {
  return (
    <Section customStyle="about-us-details-section">
      {ABOUT_US_DETAILS.map((item, index) => (
        <DetailsItem
          key={index}
          title={item.title}
          description={item.desciption}
          icon={item.icon}
        />
      ))}
    </Section>
  );
};

export default Details;
