import React from "react";
import Page from "../../components/Page/Page";
import Details from "./components/Details/Details";
import Introduction from "./components/Introduction/Introduction";
import OurJourney from "./components/OurJourney/OurJourney";

import "./AboutUs.css";

const AboutUs = () => {
  return (
    <Page>
      <Introduction />
      <Details />
      <OurJourney />
    </Page>
  );
};

export default AboutUs;
