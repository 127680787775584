import React from "react";
import "./OurJourney.css";
import Section from "../../../../components/Section/Section";
import { useTranslation } from "react-i18next";

function OurJourney() {
  const { t } = useTranslation();
  return (
    <Section
      customStyle="our-work-section"
      title={t("our_work.our-journey.title")}
    >
      <div className="our-work-section-text">
        <p>{t("our_work.our-journey.text")}</p>
      </div>
    </Section>
  );
}

export default OurJourney;
