import React from "react";
import "./MasterIcon.css";

function MasterIconComponent(props) {
  return (
    <div className="container master-icon">
      <img
        className="master-icon-img img-fluid"
        src={props.icon}
        alt="Master Icon"
      />

      <div className="master-icon-title">
        <p>{props.title}</p>
      </div>

      <div className="master-icon-description">
        <p>{props.description}</p>
      </div>
    </div>
  );
}

export default MasterIconComponent;
