import React from "react";

import "./DetailText.css";

import { useTranslation } from "react-i18next";
import i18n from "../../../../../../../i18n/i18n";

function DetailTextComponent(props) {
  const { t } = useTranslation();
  return (
    <>
      {props.domain !== undefined ? (
        <div className="details-text">
          <h2 className="row detail-title" style={{ textAlign: "left" }}>
            {t(`home.domains.${props.domain}.title`)}
          </h2>
          <p className="row subtitle-text" style={{ textAlign: "left" }}>
            {t(`home.domains.${props.domain}.subtitle`)}
          </p>
          <p className="row">{t(`home.domains.${props.domain}.description`)}</p>

          {i18n.exists(`home.domains.${props.domain}.sectionTitle`) && (
            <h2
              className="row section-title-text"
              style={{ textAlign: "left" }}
            >
              {t(`home.domains.${props.domain}.sectionTitle`)}
            </h2>
          )}
          {i18n.exists(`home.domains.${props.domain}.sectionDescription`) && (
            <p className="row">
              {t(`home.domains.${props.domain}.sectionDescription`)}
            </p>
          )}
        </div>
      ) : null}
    </>
  );
}

export default DetailTextComponent;
