import { ReactComponent as FacebookIcon } from "../assets/facebook_icon.svg";
import { ReactComponent as LinkdinIcon } from "../assets/linkdin_icon.svg";
import { ReactComponent as InstagramIcon } from "../assets/instagram_icon.svg";
import { ReactComponent as LocationsIcon } from "../assets/AboutUs/locations.svg";
import { ReactComponent as TeamsIcon } from "../assets/AboutUs/teams.svg";
import { ReactComponent as RelationIcon } from "../assets/AboutUs/relation.svg";
import { ReactComponent as UniversityIcon } from "../assets/AboutUs/university.svg";
import { ReactComponent as GenderIcon } from "../assets/AboutUs/gender.svg";

export const BASE_URL = "https://toffy.work/back/api/v1/";
export const JOBS = "job/";
export const ACTIVE_JOBS = "status/active";
export const GET_IN_TOUCH = "contactUs";
export const COMPANY_LINKS = [
  {
    title: "Facebook",
    icon: <FacebookIcon />,
    url: "https://www.facebook.com/EXALT.Technologies/",
  },
  {
    title: "Linkedin",
    icon: <LinkdinIcon />,
    url: "https://www.linkedin.com/company/exalt-technologies/",
  },
  {
    title: "Instagram",
    icon: <InstagramIcon />,
    url: "https://www.instagram.com/exalt_technologies/",
  },
];
export const ABOUT_US_DETAILS = [
  {
    title: "about_us.locations.title",
    desciption: "about_us.locations.description",
    icon: <LocationsIcon />,
  },
  {
    title: "about_us.teams.title",
    desciption: "about_us.teams.description",
    icon: <TeamsIcon />,
  },
  {
    title: "about_us.gender.title",
    desciption: "about_us.gender.description",
    icon: <GenderIcon />,
  },
  {
    title: "about_us.relation.title",
    desciption: "about_us.relation.description",
    icon: <RelationIcon />,
  },
  {
    title: "about_us.university.title",
    desciption: "about_us.university.description",
    icon: <UniversityIcon />,
  },
];
export const THEME = {
  DARK: "dark",
  LIGHT: "light",
};
export const PLACEHOLDER_POSITION = {
  TOP: "top",
  INSIDE: "inside",
};
export const STATUS = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
};
export const ERRORS = {
  REQUIRED: "Required Field",
  INVALID_EMAIL: "Please enter a valid email",
  INVALID_PHONE: "Please enter a valid phone number",
  NUMBER: "Must be a number",
  INVALID_VALUE: "Invalid Value",
};
export const GET_IN_TOUCH_INITIAL_STATE = {
  name: "",
  email: "",
  phone: "",
  company: "",
  message: "",
  privacyAgreed: false,
};
export const GET_IN_TOUCH_ERRORS_INITIAL_STATE = {
  name: false,
  email: false,
  phone: false,
  message: false,
  privacyAgreed: false,
};
export const APPLY_JOB_STATE = {
  SUCCESS: {
    title: "Thank You!",
    message: "Your job application was submitted successfully!",
    status: STATUS.SUCCESS,
  },
  ERROR: {
    title: "Failed!",
    message: "Your job application was not submitted. Please try again later.",
    status: STATUS.ERROR,
  },
};
export const APPLY_JOB_ERRORS_INITIAL_STATE = {
  personalInfo: {
    fname: false,
    lname: false,
    email: false,
    phone: false,
  },
  universityInfo: {
    name: false,
    discipline: false,
    gpa: false,
  },
  scores: {
    highSchool: false,
    dataStructure: false,
    dataBase: false,
    programmingLanguage: false,
  },
  resume: false,
};
export const PERSONAL_INFO_INITIAL_STATE = {
  fname: "",
  lname: "",
  email: "",
  phone: "",
};
export const UNIVERSITY_INFO_INITIAL_STATE = {
  name: "",
  discipline: "",
  gpa: "",
};
export const SCORES_INITIAL_STATE = {
  highSchool: "",
  dataStructure: "",
  dataBase: "",
  programmingLanguage: "",
};
export const UNIVERSITIES = [
  "Al-Quds University",
  "An-Najah National University",
  "Arab American University",
  "Birzeit University",
  "Palestine Polytechnic University",
  "Palestine Technical University - Kadoorie",
];
export const DISCIPLINES = ["Computer Science", "Computer Engineering"];
export const PROGRAMMING_LANGUAGES = ["Java", "C++", "Python", "C#", "C"];
export const EXPERIENCE_LEVELS = ["Fresh", "3 Years", "4+ Years"];
export const RESUME_FILE_ALLOWED_EXTESIONS = [
  ".doc",
  ".docx",
  ".pdf",
  ".ppt",
  ".pptx",
  ".png",
  ".jpg",
  ".jpeg",
];
export const JOBS_REDUCER_ACTIONS = {
  SET_JOBS: "SET_JOBS",
  SET_LOADING: "SET_LOADING",
  SET_ERROR: "SET_ERROR",
};
export const DETAILED_JOBS_ACTIONS = {
  ADD_NEW_JOB: "ADD_NEW_JOB",
  SET_LOADING: "SET_LOADING_JOB",
  SET_ERROR: "SET_ERROR_JOB",
};
