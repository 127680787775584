import { DETAILED_JOBS_ACTIONS } from "../../constants/constants";

const INITIAL_STATE = {
  jobs: [],
  loading: false,
  error: null,
};

const detailedJobsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DETAILED_JOBS_ACTIONS.ADD_NEW_JOB:
      return {
        ...state,
        jobs: [
          ...state.jobs.filter(
            (job) => job.id.toString() !== action.payload.id.toString()
          ),
          action.payload,
        ],
      };
    case DETAILED_JOBS_ACTIONS.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case DETAILED_JOBS_ACTIONS.SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default detailedJobsReducer;
