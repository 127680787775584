import React from "react";
import "./Master.css";
import MasterIconComponent from "./Components/MasterIcon/MasterIcon";

import UiUxIcon from "../../../../../assets/Domains/ux_icon.svg";
import WebServicesIcon from "../../../../../assets/Domains/web_services_icon.svg";
import MobileAppsIcon from "../../../../../assets/Domains/mobile_apps_icon.svg";
import DvIcon from "../../../../../assets/Domains/dv_icon.svg";
import ApiIcon from "../../../../../assets/Domains/api_icon.svg";
import CloudIcon from "../../../../../assets/Domains/cloud_icon.svg";
import QaIcon from "../../../../../assets/Domains/qa_icon.svg";
import DevOpsIcon from "../../../../../assets/Domains/devops_icon.svg";
import FirmwareIcon from "../../../../../assets/Domains/firmware_icon.svg";

import { useTranslation } from "react-i18next";

function MasterComponent(props) {
  const { t } = useTranslation();
  return (
    <div
      className="container master"
      data-aos="zoom"
      data-aos-duration="600"
      data-aos-once="true"
    >
      <div className="row">
        <div className="col-sm">
          <div className="domains-master-icons">
            <div className="row" style={{ marginBottom: "44px" }}>
              <div
                className="col-sm domain"
                data-aos="fade-up-right"
                data-aos-once="true"
                onClick={() =>
                  props.handleDetailsClick("User Experience & Graphic Design")
                }
                onMouseOver={(e) => {
                  e.currentTarget.classList.add("master-icon-hover");
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.classList.remove("master-icon-hover");
                }}
              >
                <MasterIconComponent
                  icon={UiUxIcon}
                  title={t(
                    "home.domains.User Experience & Graphic Design.title"
                  )}
                  description={t(
                    "home.domains.User Experience & Graphic Design.subtitle"
                  )}
                />
              </div>

              <div
                className="col-sm domain"
                data-aos="fade-down-left"
                data-aos-once="true"
                onClick={() => props.handleDetailsClick("Web Development")}
                onMouseOver={(e) => {
                  e.currentTarget.classList.add("master-icon-hover");
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.classList.remove("master-icon-hover");
                }}
              >
                <MasterIconComponent
                  icon={WebServicesIcon}
                  title={t("home.domains.Web Development.title")}
                  description={t("home.domains.Web Development.subtitle")}
                />
              </div>
              <div
                className="col-sm domain"
                data-aos="fade-up"
                data-aos-once="true"
                onClick={() => props.handleDetailsClick("Mobile Apps")}
                onMouseOver={(e) => {
                  e.currentTarget.classList.add("master-icon-hover");
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.classList.remove("master-icon-hover");
                }}
              >
                <MasterIconComponent
                  icon={MobileAppsIcon}
                  title={t("home.domains.Mobile Apps.title")}
                  description={t("home.domains.Mobile Apps.subtitle")}
                />
              </div>
            </div>

            <div className="row" style={{ marginBottom: "44px" }}>
              <div
                className="col-sm domain"
                data-aos="fade-up"
                data-aos-once="true"
                onClick={() => props.handleDetailsClick("Design Verification")}
                onMouseOver={(e) => {
                  e.currentTarget.classList.add("master-icon-hover");
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.classList.remove("master-icon-hover");
                }}
              >
                <MasterIconComponent
                  icon={DvIcon}
                  title={t("home.domains.Design Verification.title")}
                  description={t("home.domains.Design Verification.subtitle")}
                />
              </div>
              <div
                className="col-sm domain"
                data-aos="fade-up"
                data-aos-once="true"
                onClick={() => props.handleDetailsClick("API & Backend")}
                onMouseOver={(e) => {
                  e.currentTarget.classList.add("master-icon-hover");
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.classList.remove("master-icon-hover");
                }}
              >
                <MasterIconComponent
                  icon={ApiIcon}
                  title={t("home.domains.API & Backend.title")}
                  description={t("home.domains.API & Backend.subtitle")}
                />
              </div>
              <div
                className="col-sm domain"
                data-aos="fade-down-right"
                data-aos-once="true"
                onClick={() => props.handleDetailsClick("Cloud Solutions")}
                onMouseOver={(e) => {
                  e.currentTarget.classList.add("master-icon-hover");
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.classList.remove("master-icon-hover");
                }}
              >
                <MasterIconComponent
                  icon={CloudIcon}
                  title={t("home.domains.Cloud Solutions.title")}
                  description={t("home.domains.Cloud Solutions.subtitle")}
                />
              </div>
            </div>

            <div
              className="row"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div
                className="col-sm-4 domain"
                data-aos="fade-up-left"
                data-aos-once="true"
                onClick={() => props.handleDetailsClick("Quality Assurance")}
                onMouseOver={(e) => {
                  e.currentTarget.classList.add("master-icon-hover");
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.classList.remove("master-icon-hover");
                }}
              >
                <MasterIconComponent
                  icon={QaIcon}
                  title={t("home.domains.Quality Assurance.title")}
                  description={t("home.domains.Quality Assurance.subtitle")}
                />
              </div>
              <div
                className="col-sm-4 domain"
                data-aos="fade-down"
                data-aos-once="true"
                onClick={() => props.handleDetailsClick("DevOps")}
                onMouseOver={(e) => {
                  e.currentTarget.classList.add("master-icon-hover");
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.classList.remove("master-icon-hover");
                }}
              >
                <MasterIconComponent
                  icon={DevOpsIcon}
                  title={t("home.domains.DevOps.title")}
                  description={t("home.domains.DevOps.subtitle")}
                />
              </div>
              <div
                className="col-sm-4 domain"
                data-aos="fade-down"
                data-aos-once="true"
                onClick={() => props.handleDetailsClick("Firmware Development")}
                onMouseOver={(e) => {
                  e.currentTarget.classList.add("master-icon-hover");
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.classList.remove("master-icon-hover");
                }}
              >
                <MasterIconComponent
                  icon={FirmwareIcon}
                  title={t("home.domains.Firmware Development.title")}
                  description={t("home.domains.Firmware Development.subtitle")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MasterComponent;
