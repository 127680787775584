import React from "react";
import "./HowWeIconsGrid.css";
import Section from "../../../../components/Section/Section";
import GridItem from "./components/GridItem/GridItem";
import IdeasIcon from "../../../../assets/HowWeDoIt/ideas.svg";
import InnovationsIcon from "../../../../assets/HowWeDoIt/Innovations.svg";
import ProductDevelopmentIcon from "../../../../assets/HowWeDoIt/ProductDevelopment.svg";
import ScrumTeamIcon from "../../../../assets/HowWeDoIt/ScrumTeam.svg";
import { useTranslation } from "react-i18next";

function HowWeIconsGrid() {
  const { t } = useTranslation();
  return (
    <Section customStyle="how-we-icons-grid" title={t("our_work.Icons.title")}>
      <div className="how-we-icons">
        <div className="row">
          <div className="col our-work-icon-div">
            <GridItem
              img={IdeasIcon}
              title={t("our_work.Icons.Ideas.title")}
              description={t("our_work.Icons.Ideas.description")}
            />
          </div>
          <div className="col our-work-icon-div">
            <GridItem
              img={InnovationsIcon}
              title={t("our_work.Icons.Innovations.title")}
              description={t("our_work.Icons.Innovations.description")}
            />
          </div>
        </div>

        <div className="row">
          <div className="col our-work-icon-div ">
            <GridItem
              img={ScrumTeamIcon}
              title={t("our_work.Icons.Scrum Team.title")}
              description={t("our_work.Icons.Scrum Team.description")}
            />
          </div>
          <div className="col our-work-icon-div">
            <GridItem
              img={ProductDevelopmentIcon}
              title={t("our_work.Icons.Product Development.title")}
              description={t("our_work.Icons.Product Development.description")}
            />
          </div>
        </div>
      </div>
    </Section>
  );
}

export default HowWeIconsGrid;
