import { fetchJobDetails } from "../../apis/api";
import { DETAILED_JOBS_ACTIONS } from "../../constants/constants";

export const getJobDetails = (jobId) => (dispatch) => {
  dispatch({ type: DETAILED_JOBS_ACTIONS.SET_LOADING, payload: true });
  dispatch({ type: DETAILED_JOBS_ACTIONS.SET_ERROR, payload: false });
  fetchJobDetails(jobId, {
    onSuccess: (response) => {
      dispatch({ type: DETAILED_JOBS_ACTIONS.SET_LOADING, payload: false });
      dispatch({
        type: DETAILED_JOBS_ACTIONS.ADD_NEW_JOB,
        payload: response.data,
      });
    },
    onFailure: (error) => {
      dispatch({ type: DETAILED_JOBS_ACTIONS.SET_LOADING, payload: false });
      dispatch({ type: DETAILED_JOBS_ACTIONS.SET_ERROR, payload: error });
    },
  });
};
