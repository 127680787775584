import React, { useRef, useState } from "react";
import { ReactComponent as ChevronIcon } from "../../assets/Domains/next.svg";
import { THEME } from "../../constants/constants";
import useClickOutside from "../../hooks/useClickOutside";
import "./Dropdown.css";

const DropdownOption = ({ value, onSelect }) => {
  return (
    <div
      className="dropdown-option"
      onClick={() => onSelect(value)}
      onKeyDown={(e) => e.key === "Enter" && onSelect(value)}
      tabIndex={0}>
      {value}
    </div>
  );
};

const Dropdown = ({
  selectedOption,
  options,
  onSelect,
  theme,
  customStyle,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  useClickOutside(ref, () => setIsOpen(false));
  return (
    <div
      className={`dropdown ${
        theme === THEME.DARK ? "dark-theme" : "light-theme"
      } ${customStyle}`}
      onClick={() => setIsOpen((prevState) => !prevState)}
      onKeyDown={(e) =>
        e.key === "Enter" && setIsOpen((prevState) => !prevState)
      }
      ref={ref}
      tabIndex={0}>
      <span>{selectedOption}</span>
      <span className={isOpen ? "opened" : "closed"}>
        <ChevronIcon height="22" />
      </span>
      {isOpen && (
        <div
          className={`dropdown-options ${
            theme === THEME.DARK ? "dark-theme" : "light-theme"
          }`}>
          {options.map((option) => (
            <DropdownOption key={option} value={option} onSelect={onSelect} />
          ))}
        </div>
      )}
    </div>
  );
};
export default Dropdown;
