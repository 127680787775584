import React from "react";

import "./FormField.css";

const FormField = ({ error, placeholder, children }) => {
  return (
    <div className="form-field">
      <span className="form-field-placeholder">{placeholder}</span>
      {children}
    </div>
  );
};

export default FormField;
