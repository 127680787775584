import React from "react";
import { useTranslation } from "react-i18next";
import Page from "../../components/Page/Page";
import Section from "../../components/Section/Section";
import useWindowDimensions from "../../hooks/useWindowDimensions";

import "./NotFound.css";

const NotFound = () => {
  const { t } = useTranslation();
  const { height } = useWindowDimensions();
  return (
    <Page customStyle="not-found-page">
      <Section
        title={t("common.not_found")}
        style={{
          height:
            height -
            (document.getElementById("navbar")?.clientHeight +
              document.getElementById("company-info")?.clientHeight),
        }}
      ></Section>
    </Page>
  );
};

export default NotFound;
