import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useClickOutside from "../../hooks/useClickOutside";
import TextInput from "../TextInput/TextInput";

import "./AutocompleteInput.css";

const Option = ({ title, onClick }) => {
  return (
    <div
      className="option"
      onClick={onClick}
      tabIndex={0}
      onKeyDown={(e) => e.key === "Enter" && onClick(e)}
    >
      {title}
    </div>
  );
};
const AutocompleteInput = ({ value, onChange, options, ...otherProps }) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [state, setState] = useState({
    filteredSuggestions: [],
    showSuggestions: false,
  });

  const handleChange = (e) => {
    const userInput = e.currentTarget.value;
    const filteredSuggestions = options.filter(
      (suggestion) =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );
    onChange(userInput);
    setState({
      filteredSuggestions,
      showSuggestions: true,
    });
  };

  const handleClickOption = (e) => {
    onChange(e.currentTarget.innerText);
    setState({
      filteredSuggestions: [],
      showSuggestions: false,
    });
  };

  const handleClickOutside = () => {
    setState({ ...state, showSuggestions: false });
  };

  const handleFocus = (e) => {
    if (e?.currentTarget?.value?.length) {
      handleChange(e);
    }
  };

  useClickOutside(ref, handleClickOutside);

  return (
    <div className="autocomplete-input" ref={ref}>
      <TextInput
        {...otherProps}
        value={value}
        onChange={handleChange}
        autoComplete="off"
        onClick={handleFocus}
      />
      {state.showSuggestions && (
        <div className="options-list">
          {state.filteredSuggestions.map((suggestion) => (
            <Option
              key={suggestion}
              title={suggestion}
              onClick={handleClickOption}
            />
          ))}
          {state?.filteredSuggestions?.length < 1 && (
            <em className="no-suggestion">{t("common.no_suggestions")}</em>
          )}
        </div>
      )}
    </div>
  );
};

export default AutocompleteInput;
