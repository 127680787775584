import React from "react";
import "./PrivacyPolicy.css";
const privacyPolicyContent = [
  {
    title: "Information We Collect",
    description: "We may collect the following types of information:",
    points: [
      {
        title: "• Personal Information:",
        content:
          "Name, email address, phone number, and other information you provide directly to us.",
      },
      {
        title: "• Usage Data:",
        content:
          "Information about how you use our website, such as your IP address, browser type, operating system, pages visited, and the date/time of your visit.",
      },
      {
        title: "• Cookies and Tracking Technologies:",
        content:
          "We may use cookies and similar Tracking technologies to monitor activity on our website and store certain information.",
      },
    ],
  },
  {
    title: "How We Use Your Information",
    description:
      "We use the information we collect for various purposes, including:",
    points: [
      {
        content: "• To provide and maintain our website and services.",
      },
      {
        content: "• To improve, personalize, and expand our website.",
      },
      {
        content:
          "• To communicate with you, including responding to your inquiries, sending updates, and marketing communications.",
      },
      {
        content: "• To analyze website usage and optimize the user experience.",
      },
      {
        content: "• To comply with legal obligations and protect our rights.",
      },
    ],
  },
  {
    title: "Sharing Your Information",
    description: " We may share your information with:",
    points: [
      {
        title: "• Service Providers:",
        content:
          "Third-party companies and individuals who can assist us in operating our website and providing services to you.",
      },
      {
        title: "• Legal Requirements:",
        content:
          "We may disclose your information if required by law or in response to valid requests by public authorities.",
      },
    ],
  },
  {
    title: "Security of Your Information",
    description:
      "We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or method of electronic storage is completely secure",
  },
  {
    title: "Your Rights",
    description: "You have the right to:",
    points: [
      {
        content:
          "• Access, update, or delete the personal information we hold about you.",
      },
      {
        content: "• Object to the processing of your personal information.",
      },
      {
        content:
          "• Request the restriction of processing your personal information.",
      },
      {
        content:
          "• Withdraw consent at any time where we rely on your consent to process your information.",
      },
    ],
  },
  {
    title: "Changes to This Privacy Policy",
    description:
      "We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. It is recommended that you review this Privacy Policy periodically for any changes.",
  },
  {
    title: "Contact Us",
    description:
      "If you have any questions about this Privacy Policy, you can contact us via email at: ",
  },
];
const PrivacyPolicy = () => {
  return (
    <div className="privacy-container">
      <div class="title privacy-title" id="privacy-title">
        <p>Privacy Policy EXALT Technologies Ltd.</p>
      </div>
      <p className="gray-text">
        Welcome to EXALT Technologies Ltd Privacy Policy. We are committed to
        protecting your personal information and your right to privacy. This
        Privacy Policy explains what information we collect, how we use it, and
        your rights regarding your personal information.
      </p>
      <p className="gray-text">
        By using the Service, You agree to the collection and use of information
        in accordance with this Privacy Policy.
      </p>
      {privacyPolicyContent.map((element) => {
        if (element.title === "Contact Us") {
          return (
            <div className="">
              <p className="policy-title">{element.title}</p>
              <p className="gray-text">
                {element.description}
                <a
                  href="mailto:Privacy.Policy@EXALT-Tech.com"
                  className="privacy-email-anchor"
                >
                  Privacy.Policy@EXALT-Tech.com
                </a>
              </p>
            </div>
          );
        }
        return (
          <div className="">
            <p className="policy-title">{element.title}</p>
            <p className="gray-text">{element.description}</p>
            {element?.points &&
              element?.points.map((point) => {
                return (
                  <p className="point gray-text">
                    {<b className="black-text">{point.title}</b>}
                    {point.content}
                  </p>
                );
              })}
          </div>
        );
      })}
    </div>
  );
};

export default PrivacyPolicy;
