import { fetchJobs } from "../../apis/api";
import { JOBS_REDUCER_ACTIONS } from "../../constants/constants";

export const getJobs = () => (dispatch) => {
  dispatch({ type: JOBS_REDUCER_ACTIONS.SET_LOADING, payload: true });
  dispatch({ type: JOBS_REDUCER_ACTIONS.SET_ERROR, payload: false });
  fetchJobs({
    onSuccess: (response) => {
      dispatch({ type: JOBS_REDUCER_ACTIONS.SET_LOADING, payload: false });
      dispatch({
        type: JOBS_REDUCER_ACTIONS.SET_JOBS,
        payload: response?.data ?? [],
      });
    },
    onFailure: (error) => {
      dispatch({ type: JOBS_REDUCER_ACTIONS.SET_LOADING, payload: false });
      dispatch({ type: JOBS_REDUCER_ACTIONS.SET_ERROR, payload: error });
    },
  });
};
