import React from "react";
import "./OurWork.css";

import HowWeHeaderComponent from "./components/HowWeHeader/HowWeHeader";
import HowWeIconsGridComponent from "./components/HowWeIconsGrid/HowWeIconsGrid";
import WhyWorkWithUsComponent from "./components/WhyWorkWithUs/WhyWorkWithUs";
import TechnologicalSolutionsComponent from "./components/TechnologicalSolutions/TechnologicalSolutions";

function OurWork() {
  return (
    <>
      <HowWeHeaderComponent />
      <HowWeIconsGridComponent />
      <WhyWorkWithUsComponent />
      <TechnologicalSolutionsComponent />
    </>
  );
}

export default OurWork;
