import React from "react";
import { useTranslation } from "react-i18next";
import Section from "../../../../components/Section/Section";

import "./Introduction.css";

const Introduction = () => {
  const { t } = useTranslation();
  return (
    <Section title={t("about_us.title")} customStyle="about-us-intro-section">
      <div className="about-us-intro-description" tabIndex={0}>
        {t("about_us.description")}
      </div>
    </Section>
  );
};

export default Introduction;
