import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AutocompleteInput from "../../../components/AutocompleteInput/AutocompleteInput";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Button from "../../../components/Button/Button";
import Dropdown from "../../../components/Dropdown/Dropdown";
import FormField from "../../../components/FormField/FormField";
import Popup from "../../../components/Popup/Popup";
import Section from "../../../components/Section/Section";
import TextInput from "../../../components/TextInput/TextInput";
import LoadingIndicator from "../../../components/LoadingIndicator/LoadingIndicator";
import {
  APPLY_JOB_ERRORS_INITIAL_STATE,
  APPLY_JOB_STATE,
  DISCIPLINES,
  EXPERIENCE_LEVELS,
  PERSONAL_INFO_INITIAL_STATE,
  PROGRAMMING_LANGUAGES,
  RESUME_FILE_ALLOWED_EXTESIONS,
  SCORES_INITIAL_STATE,
  THEME,
  UNIVERSITIES,
  UNIVERSITY_INFO_INITIAL_STATE,
} from "../../../constants/constants";
import { validateApplyNowForm } from "../../../utils/utils";

import "./ApplyForm.css";
import { useParams } from "react-router-dom";
import axios from "axios";


const validateScore = (strNumber) => {
  try {
    const re = /^[0-9,]*\.?[0-9,]*$/;
    const num = parseFloat(strNumber)
    if(num >= 0 && num <= 100 && re.test(strNumber)) {
      return true
    }
    return false
  } catch {
    return false
  }
}

const ApplyForm = () => {
  const { id } = useParams();
  const job = useSelector((state) =>
    state.detailedJobsReducer.jobs.find((job) => job.id.toString() === id)
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const [errors, setErrors] = useState({ ...APPLY_JOB_ERRORS_INITIAL_STATE });
  const [resume, setResume] = useState(null);
  const [personalInfo, setPersonalInfo] = useState({
    ...PERSONAL_INFO_INITIAL_STATE,
  });
  const [selectedExperienceOption, setSelectedExperienceOption] = useState(
    EXPERIENCE_LEVELS[0]
  );
  const [universityInfo, setUniversityInfo] = useState({
    ...UNIVERSITY_INFO_INITIAL_STATE,
  });
  const [selectedProgLang, setSelectedProgLang] = useState({
    title: PROGRAMMING_LANGUAGES[0],
    score: "",
  });
  const [scores, setScores] = useState({ ...SCORES_INITIAL_STATE });
  const [popupConfig, setPopupConfig] = useState({
    visible: false,
    state: APPLY_JOB_STATE.SUCCESS,
  });
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const handleSelectExperience = useCallback(
    (value) => {
      setSelectedExperienceOption(value);
      if (value === EXPERIENCE_LEVELS[1]) {
        setScores({ ...SCORES_INITIAL_STATE });
        setSelectedProgLang({
          title: PROGRAMMING_LANGUAGES[0],
          score: "",
        });
      }
    },
    [setSelectedExperienceOption, setScores, setSelectedProgLang]
  );

  const PROGRAMMING_LANGUAGES_KEYS = [
    { name: "Java", key: "JAVA" },
    { name: "C++", key: "C_PLUS_PLUS" },
    { name: "Python", key: "PYTHON" },
    { name: "C#", key: "C_SHARP" },
    { name: "C", key: "C" },
  ];
  const EXPERIENCE_LEVELS_KEYS = [
    { name: "Fresh", key: "FRESH" },
    { name: "3 Years", key: "_3_YEARS" },
    { name: "4+ Years", key: "MORE_THAN_4_YEARS" },
  ];

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const { errors, isValidFormData } = validateApplyNowForm(
        personalInfo,
        selectedExperienceOption,
        universityInfo,
        scores,
        resume
      );
      if (!isValidFormData) {
        setErrors(errors);
        // setPopupConfig({ visible: true, state: APPLY_JOB_STATE.ERROR });
      } else {
        setErrors({ ...APPLY_JOB_ERRORS_INITIAL_STATE });
        setButtonDisabled(true);
        setShowLoadingIndicator(true);

        // send http request

        let candidate = {
          firstName: personalInfo.fname,
          lastName: personalInfo.lname,
          phoneNumber: personalInfo.phone,
          email: personalInfo.email,
          experience: EXPERIENCE_LEVELS_KEYS.find(
            (item) => item.name === selectedExperienceOption
          ).key,
          highSchoolGrade: parseInt(scores.highSchool),
          university: {
            name: universityInfo.name,
            discipline: universityInfo.discipline,
            gpa: parseInt(universityInfo.gpa),
            scores: {
              ds: parseInt(scores.dataStructure),
              db: parseInt(scores.dataBase),
              programming_language_name: PROGRAMMING_LANGUAGES_KEYS.find(
                (item) => item.name === selectedProgLang.title
              ).key,
              programming_language_grade: parseInt(scores.programmingLanguage),
            },
          },
          job: {
            id: parseInt(job.id),
          },
        };

        // stream reader for resume
        const ApplyNowFormData = new FormData();

        const jason = JSON.stringify(candidate);

        const blob = new Blob([jason], {
          type: "application/json",
        });

        // change resume file name
        const resumeNewName = new File(
          [resume],
          `${
            //remove spaces from name
            candidate.firstName.replace(/\s+/g, "") +
            "_" +
            candidate.lastName.replace(/\s+/g, "") +
            "_CV." +
            resume.name.split(".").pop()
          }`,
          { type: resume.type }
        );

        ApplyNowFormData.append("file", resumeNewName);
        ApplyNowFormData.append("candidate", blob);

        axios
          .post(
            "https://toffy.work/exalt-hire/api/v2/candidate",
            ApplyNowFormData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((res) => {
            if (res.status === 201) {
              navigate("/jobs", { state: { success: true } });
            }
          })
          .catch((err) => {
            setButtonDisabled(false);
            setShowLoadingIndicator(false);
            setPopupConfig({ visible: true, state: APPLY_JOB_STATE.ERROR });
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      personalInfo,
      selectedExperienceOption,
      universityInfo,
      scores,
      resume,
      setErrors,
      setPopupConfig,
      job,
      selectedProgLang,
    ]
  );

  useEffect(() => {
    if (!job) {
      navigate(`/jobs/${id}`, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job]);

  return (
    <Section title={t("jobs.apply")} customStyle="apply-form-section">
      {showLoadingIndicator && <LoadingIndicator />}
      <div className="apply-form">
        <div className="form-row-title">
          {t("jobs.form.personal_info.title")}
        </div>
        <div className="form-2-cols">
          <FormField
            placeholder={t("jobs.form.personal_info.fname")}
            error={errors.personalInfo.fname ?? ""}>
            <TextInput
              name="text"
              theme={THEME.LIGHT}
              value={personalInfo.fname}
              maxLength={30}
              onChange={(e) => {
                setPersonalInfo({ ...personalInfo, fname: e.target.value });
              }}
              error={errors.personalInfo.fname}
            />
          </FormField>
          <FormField
            placeholder={t("jobs.form.personal_info.lname")}
            error={errors.personalInfo.lname ?? ""}>
            <TextInput
              name="text"
              theme={THEME.LIGHT}
              value={personalInfo.lname}
              maxLength={30}
              onChange={(e) => {
                setPersonalInfo({ ...personalInfo, lname: e.target.value });
              }}
              error={errors.personalInfo.lname}
            />
          </FormField>
        </div>
        <div className="form-2-cols">
          <FormField
            placeholder={t("jobs.form.personal_info.email")}
            error={errors.personalInfo.email ?? ""}>
            <TextInput
              name="text"
              theme={THEME.LIGHT}
              value={personalInfo.email}
              onChange={(e) =>
                setPersonalInfo({ ...personalInfo, email: e.target.value })
              }
              error={errors.personalInfo.email}
            />
          </FormField>
          <FormField
            placeholder={t("jobs.form.personal_info.phone")}
            error={errors.personalInfo.phone ?? ""}>
            <TextInput
              name="text"
              theme={THEME.LIGHT}
              value={personalInfo.phone}
              onChange={(e) => {
                const re = /^[\d ()+-]+$/;
                if (e.target.value === "" || re.test(e.target.value)) {
                  setPersonalInfo({ ...personalInfo, phone: e.target.value });
                }
              }}
              error={errors.personalInfo.phone}
            />
          </FormField>
        </div>
        <div className="form-row-title">{t("jobs.form.experience.title")}</div>
        <div className="form-2-cols">
          <FormField placeholder={t("jobs.form.experience.duration")}>
            <Dropdown
              selectedOption={selectedExperienceOption}
              options={EXPERIENCE_LEVELS}
              onSelect={handleSelectExperience}
            />
          </FormField>
        </div>
        <div className="form-row-title">{t("jobs.form.education.title")}</div>
        {selectedExperienceOption === EXPERIENCE_LEVELS[0] && (
          <div className="form-4-cols">
            <FormField
              placeholder={t("jobs.form.education.school")}
              error={errors.scores.highSchool ?? ""}>
              <TextInput
                name="text"
                theme={THEME.LIGHT}
                value={scores.highSchool}
                maxLength={5}
                onChange={(e) => {
                  if(validateScore(e.target.value) || e.target.value == "") {
                    setScores({ ...scores, highSchool: e.target.value });
                  }
                }}
                error={errors.scores.highSchool}
              />
            </FormField>
          </div>
        )}
        <div className="form-3-cols">
          <FormField
            placeholder={t("jobs.form.education.university")}
            error={errors.universityInfo.name ?? ""}>
            <AutocompleteInput
              name="text"
              theme={THEME.LIGHT}
              error={errors.universityInfo.name}
              options={UNIVERSITIES}
              value={universityInfo.name}
              maxLength={50}
              onChange={(text) =>
                setUniversityInfo({
                  ...universityInfo,
                  name: text,
                })
              }
            />
          </FormField>
          <FormField
            placeholder={t("jobs.form.education.discipline")}
            error={errors.universityInfo.discipline ?? ""}>
            <AutocompleteInput
              name="text"
              theme={THEME.LIGHT}
              error={errors.universityInfo.discipline}
              options={DISCIPLINES}
              value={universityInfo.discipline}
              maxLength={50}
              onChange={(text) =>
                setUniversityInfo({
                  ...universityInfo,
                  discipline: text,
                })
              }
            />
          </FormField>
          <FormField
            placeholder={t("jobs.form.education.gpa")}
            error={errors.universityInfo.gpa ?? ""}>
            <TextInput
              customStyle="input-number"
              name="text"
              theme={THEME.LIGHT}
              value={universityInfo.gpa}
              maxLength={5}
              onChange={(e) => {
                if(validateScore(e.target.value) || e.target.value == "") {
                  setUniversityInfo({ ...universityInfo, gpa: e.target.value });
                }
              }}
              error={errors.universityInfo.gpa}
            />
          </FormField>
        </div>
        {selectedExperienceOption === EXPERIENCE_LEVELS[0] && (
          <div className="form-5-cols">
            <FormField
              placeholder={t("jobs.form.education.data_structure")}
              error={errors.scores.dataStructure ?? ""}>
              <TextInput
                customStyle="input-number"
                name="text"
                theme={THEME.LIGHT}
                value={scores.dataStructure}
                maxLength={5}
                onChange={(e) => {
                  if(validateScore(e.target.value) || e.target.value == "") {
                    setScores({ ...scores, dataStructure: e.target.value });
                  }
                }}
                error={errors.scores.dataStructure}
              />
            </FormField>
            <FormField
              placeholder={t("jobs.form.education.data_base")}
              error={errors.scores.dataBase ?? ""}>
              <TextInput
                customStyle="input-number"
                name="text"
                theme={THEME.LIGHT}
                value={scores.dataBase}
                maxLength={5}
                onChange={(e) => {
                  if(validateScore(e.target.value) || e.target.value == "") {
                    setScores({ ...scores, dataBase: e.target.value });
                  }
                }}
                error={errors.scores.dataBase}
              />
            </FormField>
            <FormField
              placeholder={t("jobs.form.education.programming_lang_score")}>
              <Dropdown
                selectedOption={selectedProgLang.title}
                options={PROGRAMMING_LANGUAGES}
                onSelect={(value) =>
                  setSelectedProgLang({ title: value, score: "" })
                }
              />
            </FormField>
            <FormField error={errors.scores.programmingLanguage ?? ""}>
              <TextInput
                customStyle="input-number"
                name="text"
                theme={THEME.LIGHT}
                value={selectedProgLang.score}
                maxLength={5}
                onChange={(e) => {
                  if(validateScore(e.target.value) || e.target.value == "") {
                    setSelectedProgLang({
                      ...selectedProgLang,
                      score: e.target.value,
                    });
                    setScores({ ...scores, programmingLanguage: e.target.value });
                  }
                }}
                error={errors.scores.programmingLanguage}
              />
            </FormField>
          </div>
        )}
        <div className="apply-form-divider" />
        <FormField error={errors.resume ?? ""}>
          <TextInput
            placeholder={t("jobs.form.resume")}
            name="file"
            type="file"
            theme={THEME.LIGHT}
            accept={RESUME_FILE_ALLOWED_EXTESIONS.join(",")}
            style={{
              backgroundColor: "red",
            }}
            value={resume}
            onChange={(e) => {
              var allowedExtensions = /(\.doc|\.docx|\.pdf|\.ppt|\.pptx|\.png|\.jpg|\.jpeg)$/i;
              console.log(e?.target?.files[0])
              if(allowedExtensions.test(e.target.value)) {
                setResume(e?.target?.files[0]);
              } else {
                setResume(null);
                if(e?.target) {
                  e.target.value = null
                }
              }
            }}
            error={errors.resume}
          />
        </FormField>
        <Button
          title={t("jobs.form.submit")}
          customStyle="submit-btn"
          theme={THEME.LIGHT}
          onClick={onSubmit}
          disabled={buttonDisabled}
        />
        <Popup
          visible={popupConfig.visible}
          {...popupConfig.state}
          onClick={() => {
            setPopupConfig({
              visible: false,
              state: APPLY_JOB_STATE.SUCCESS,
            });
          }}
        />
      </div>
    </Section>
  );
};

export default ApplyForm;
