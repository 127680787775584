import React, { useRef, useState } from "react";
import Button from "../../../../components/Button/Button";
import Section from "../../../../components/Section/Section";
import { ReactComponent as MouseIcon } from "../../../../assets/mouse.svg";
import "./Introduction.css";
import { useTranslation } from "react-i18next";
import { THEME } from "../../../../constants/constants";
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-bootstrap";

const SlideComponent = ({
  className,
  slideLabel,
  nextButtonLabel,
  onClickOurWork,
  handleClickNextButtonLabel,
}) => {
  const { t } = useTranslation();

  return (
    <div className={`carousel-item ${className}`}>
      <div className="intro-description">
        <div className="decription-label">
          <span tabIndex={0}>{slideLabel}</span>
          <button
            tabIndex={0}
            className="next-btn-label"
            onClick={() => {
              handleClickNextButtonLabel();
            }}
          >
            {nextButtonLabel}
          </button>
        </div>
      </div>
      <div className="slide-buttons">
        <Button
          title={t("our_work.title")}
          customStyle="our-work-btn"
          onClick={onClickOurWork}
          theme={THEME.DARK}
          outlined
        />

        <a href="#domains" className="scroll-down-btn">
          <MouseIcon width="40px" />
        </a>
      </div>
    </div>
  );
};

const Introduction = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const carouselRef = useRef(null);
  const handleClickOurWork = () => navigate("/our-work");
  const handleClickNextButtonLabel = () => {
    carouselRef.current.next();
  };
  return (
    <Section customStyle="intro-main-container">
      <Carousel
        ref={carouselRef}
        slide={true}
        controls={false}
        indicators={false}
        interval={7000}
        touch={true}
      >
        <Carousel.Item>
          <SlideComponent
            className="active slide1"
            slideLabel={t("home.slide1.description")}
            nextButtonLabel={t("home.slide1.button_label")}
            onClickOurWork={handleClickOurWork}
            handleClickNextButtonLabel={handleClickNextButtonLabel}
          />
        </Carousel.Item>
        <Carousel.Item>
          <SlideComponent
            className="active slide2"
            slideLabel={t("home.slide2.description")}
            nextButtonLabel={t("home.slide2.button_label")}
            onClickOurWork={handleClickOurWork}
            handleClickNextButtonLabel={handleClickNextButtonLabel}
          />
        </Carousel.Item>
      </Carousel>
    </Section>
  );
};

export default Introduction;
