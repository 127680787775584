import React from "react";
import { useTranslation } from "react-i18next";
import { STATUS } from "../../constants/constants";
import Button from "../Button/Button";

import "./Popup.css";

const Popup = ({ title, message, visible, onClick, status }) => {
  const { t } = useTranslation();
  return (
    <div className={`popup-window ${visible && "show"}`}>
      <div className="popup-content">
        <div className={`title ${status === STATUS.ERROR && "error"}`}>
          {title}
        </div>
        <div className="message">{message}</div>
        <Button onClick={onClick} title={t("common.ok")} />
      </div>
    </div>
  );
};

export default Popup;
