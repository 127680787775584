import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoadingIndicator from "../../../components/LoadingIndicator/LoadingIndicator";
import Section from "../../../components/Section/Section";
import JobItem from "./JobItem/JobItem";

import "./WorkWithUs.css";
import { getJobs } from "../../../redux/actions/jobsActions";

const WorkWithUs = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { jobs, loading, error } = useSelector((state) => state.jobsReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (jobs?.length === 0) {
      dispatch(getJobs());
    }
  }, []);

  return (
    <>
      <Section title={t("jobs.work_with_us")} customStyle="new-opening-section">
        <div className="jobs-description" tabIndex={0}>
          {t("jobs.description")}
        </div>
        <div className="jobs-items-container">
          {jobs.map((job) => (
            <JobItem
              key={job.id}
              title={job.title}
              onSelect={() => {
                window.scrollTo(0, 0);
                navigate(`/jobs/${job.id}`);
              }}
            />
          ))}
        </div>
      </Section>
      {loading && <LoadingIndicator />}
    </>
  );
};

export default WorkWithUs;
