import React from "react";
import { ReactComponent as ChevronIcon } from "../../../../assets/Domains/next.svg";
import "./JobItem.css";

const JobItem = ({ title, onSelect }) => {
  return (
    <button tabIndex={0} onClick={onSelect} className="job-item">
      <span>{title}</span>
      <ChevronIcon />
    </button>
  );
};

export default JobItem;
