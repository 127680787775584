import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/Button/Button";
import Section from "../../../components/Section/Section";
import LoadingIndicator from "../../../components/LoadingIndicator/LoadingIndicator";

import "./Job.css";
import { getJobDetails } from "../../../redux/actions/detailedJobsActions";

const Job = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { jobs, error, loading } = useSelector(
    (state) => state.detailedJobsReducer
  );
  const job = jobs.find((job) => job.id.toString() === id);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!job) {
      dispatch(getJobDetails(id));
    }
  }, [job]);

  useEffect(() => {
    if (error) {
      navigate("/not-found", { replace: true });
    }
  }, [error, navigate]);

  return (
    <>
      <Section title={job?.title} customStyle="job-details-section">
        <div
          className="job-description"
          dangerouslySetInnerHTML={{ __html: job?.description }}
        ></div>
        <Button
          title={t("jobs.apply")}
          customStyle="apply-now-btn"
          onClick={() => {
            window.scrollTo(0, 0);
            navigate(`/jobs/${id}/apply-now`);
          }}
        />
      </Section>
      {loading && <LoadingIndicator />}
    </>
  );
};

export default Job;
