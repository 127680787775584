import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../Button/Button";
import Section from "../Section/Section";
import TextArea from "../TextArea/TextArea";
import TextInput from "../TextInput/TextInput";
import FormField from "../FormField/FormField";
import {
  GET_IN_TOUCH_ERRORS_INITIAL_STATE,
  GET_IN_TOUCH_INITIAL_STATE,
  THEME,
} from "../../constants/constants";
import "./GetInTouch.css";
import { validateGetInTouchForm } from "../../utils/utils";
import { getInTouch } from "../../apis/api";
import SuccessIndicator from "../SuccessIndicator/SuccessIndicator";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import Popup from "reactjs-popup";
import { useNavigate } from "react-router-dom";
import { scrollIntoView } from "seamless-scroll-polyfill";
const contentStyle = {
  background: "transparent",
  border: "none",
  boxShadow: "none",
  padding: 0,
};
const GetInTouch = () => {
  const { t } = useTranslation();
  const recaptcha = useRef();
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/policy");
    setTimeout(() => {
      scrollIntoView(document.querySelector("#privacy-title"), {
        behavior: "instant",
        block: "center",
        inline: "center",
      });
    }, 100);
  };
  const [loading, setLoading] = useState(false);
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const [formData, setFormData] = useState({ ...GET_IN_TOUCH_INITIAL_STATE });
  const [errors, setErrors] = useState({
    ...GET_IN_TOUCH_ERRORS_INITIAL_STATE,
  });
  const [popupOpen, setPopupOpen] = useState(false);
  const closeModal = () => setPopupOpen(false);

  const handleVerifyRecaptcha = () => {
    if (!recaptcha.current.getValue()) {
      return;
    }
    setPopupOpen(false);
    setLoading(true);
    const body = {
      name: formData.name,
      email: formData.email,
      phoneNumber: formData.phone,
      companyName: formData.company,
      message: formData.message,
    };
    getInTouch(body, {
      onSuccess: handleSuccess,
      onFailure: handleFailure,
    });
  };

  const handleSuccess = useCallback(() => {
    setLoading(false);
    setFormData({ ...GET_IN_TOUCH_INITIAL_STATE });
  }, [setLoading, setFormData]);
  const handleFailure = useCallback(
    (error) => {
      setShowLoadingIndicator(false);
      setLoading(false);
      toast.error(t("get_in_touch.form.error_message"), {
        className: "toast-style",
        theme: "colored",
        position: "bottom-right",
      });
    },
    [setLoading]
  );
  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const { errors, isValidFormData } = validateGetInTouchForm(formData);
      if (!isValidFormData) {
        setErrors(errors);
      } else {
        setErrors({
          ...GET_IN_TOUCH_ERRORS_INITIAL_STATE,
        });
        setPopupOpen(true);
      }
    },
    [formData, handleSuccess, handleFailure]
  );
  useEffect(() => {
    setTimeout(() => setShowLoadingIndicator(loading), loading ? 0 : 1000);
  }, [loading, setShowLoadingIndicator]);
  return (
    <>
      <Section
        id={t("get_in_touch.title")}
        title={t("get_in_touch.title")}
        customStyle="get-in-touch-section-container"
      >
        <div className="get-in-touch-main-container">
          <form className="get-in-touch-form">
            <div className="form-row">
              <FormField error={errors.name ?? ""}>
                <TextInput
                  placeholder={t("get_in_touch.form.name")}
                  theme={THEME.DARK}
                  value={formData.name}
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                  error={errors.name}
                />
              </FormField>
              <FormField error={errors.email}>
                <TextInput
                  placeholder={t("get_in_touch.form.email")}
                  name="email"
                  theme={THEME.DARK}
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                  error={errors.email}
                />
              </FormField>
            </div>
            <div className="form-row">
              <FormField>
                <TextInput
                  placeholder={t("get_in_touch.form.phone")}
                  theme={THEME.DARK}
                  value={formData.phone}
                  onChange={(e) => {
                    const re = /^[\d ()+-]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      setFormData({ ...formData, phone: e.target.value });
                    }
                  }}
                />
              </FormField>
              <FormField>
                <TextInput
                  placeholder={t("get_in_touch.form.company_name")}
                  theme={THEME.DARK}
                  value={formData.company}
                  onChange={(e) =>
                    setFormData({ ...formData, company: e.target.value })
                  }
                />
              </FormField>
            </div>
            <FormField error={errors.message}>
              <TextArea
                placeholder={t("get_in_touch.form.message")}
                theme={THEME.DARK}
                value={formData.message}
                onChange={(e) =>
                  setFormData({ ...formData, message: e.target.value })
                }
                error={errors.message}
              />
            </FormField>
            <div className="agreement-submit-section">
              <FormField error={errors.privacyAgreed}>
                <div className={`privacy-policy-field`}>
                  <input
                    className={`checkbox-input  ${
                      errors.privacyAgreed && "checkbox-input-error"
                    }`}
                    type="checkbox"
                    id="privacyAgreed"
                    checked={formData.privacyAgreed}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        privacyAgreed: e.target.checked,
                      });
                    }}
                  />
                  <label for="privacyAgreed">
                    {t("get_in_touch.form.agreement")}
                  </label>
                  <a className="privacy-policy-text" onClick={handleClick}>
                    {t("common.privacy_policy")}
                  </a>
                </div>
              </FormField>
              <div className="form-last-row">
                <Button
                  title={t("get_in_touch.form.send")}
                  customStyle="send-btn"
                  theme={THEME.DARK}
                  onClick={onSubmit}
                  type="submit"
                />
              </div>
            </div>
          </form>
        </div>
      </Section>
      {showLoadingIndicator && <SuccessIndicator loading={loading} />}
      <Popup
        open={popupOpen}
        closeOnDocumentClick
        onClose={closeModal}
        contentStyle={contentStyle}
      >
        <ReCAPTCHA
          ref={recaptcha}
          sitekey={process.env.REACT_APP_SITE_KEY}
          onChange={handleVerifyRecaptcha}
        />
      </Popup>
    </>
  );
};

export default GetInTouch;
