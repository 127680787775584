import React from "react";

import "./ClientLogo.css";

function ClientLogo(props) {
  return (
    <div
      data-aos={props.aos}
      className="client-icon-container"
      data-aos-once="true"
    >
      <img
        src={props.src}
        alt={props.alt}
        tabIndex={0}
        className="image-fluid client-icon"
        onMouseOver={(e) => (e.currentTarget.src = props.hover)}
        onMouseOut={(e) => (e.currentTarget.src = props.src)}
        onFocus={(e) => (e.currentTarget.src = props.hover)}
        onBlur={(e) => (e.currentTarget.src = props.src)}
      />
    </div>
  );
}

export default ClientLogo;
