import React from "react";
import "./WhyWorkListItem.css";

function WhyWorkListItem(props) {
  return (
    <div className="row why-work-with-us-item">
      <div className="why-work-with-us-item-title">
        <p>{props.title}</p>
      </div>
      <div className="why-work-with-us-item-text">
        <p>{props.description} </p>
      </div>
    </div>
  );
}

export default WhyWorkListItem;
