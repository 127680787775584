import React from "react";

import "./SuccessIndicator.css";

const SuccessIndicator = ({ loading = true }) => {
  return (
    <div className="success-window">
      <div className={`circle-loader ${!loading && "load-complete"}`}>
        <div
          className="checkmark draw"
          style={loading ? {} : { display: "block" }}
        ></div>
      </div>
    </div>
  );
};

export default SuccessIndicator;
