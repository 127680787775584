import React from "react";
import "./TechnologicalSolutions.css";
import Section from "../../../../components/Section/Section";
import { useTranslation } from "react-i18next";

function TechnologicalSolutions() {
  const { t } = useTranslation();
  return (
    <Section
      customStyle="technological-solutions-section"
      title={t("our_work.technicalSolutions.title")}
    >
      <div className="technological-solutions-text">
        <p> {t("our_work.technicalSolutions.text")} </p>
      </div>
    </Section>
  );
}

export default TechnologicalSolutions;
