import React, { useRef, useState } from "react";
import { THEME } from "../../constants/constants";
import { ReactComponent as UploadIcon } from "../../assets/upload.svg";
import "./TextInput.css";
import { MdError } from "react-icons/md";

const getFileName = (path) => {
  const tokens = path?.split("\\");
  return tokens?.[tokens?.length - 1];
};

const TextInput = ({
  name,
  placeholder,
  customStyle,
  theme,
  value,
  onChange,
  error = false,
  ...otherProps
}) => {
  const fileInputRef = useRef(null);
  return (
    <>
      {name === "file" && (
        <label
          htmlFor={placeholder}
          className={`text-input ${
            theme === THEME.DARK
              ? value
                ? "dark-theme"
                : "dark-theme dark-upload-placeholder"
              : value
              ? "light-theme"
              : "light-theme light-upload-placeholder"
          } ${customStyle} ${error && "error"} input-file`}
          tabIndex={0}
          onKeyDown={(e) => e.key === "Enter" && fileInputRef?.current?.click()}
        >
          <input
            ref={fileInputRef}
            id={placeholder}
            type="file"
            name={name}
            hidden
            onChange={onChange}
            {...otherProps}
          />
          <span>{value?.name || placeholder}</span>
          <div className="upload-icon">
            <UploadIcon width="20px" />
          </div>
        </label>
      )}
      {name !== "file" && (
        <div className="input-container">
          <input
            name={name}
            type="text"
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            className={`text-input ${
              theme === THEME.DARK ? "dark-theme" : "light-theme"
            } ${customStyle} ${error && "error"}`}
            {...otherProps}
          />
          {error && <MdError className="error-icon" />}
        </div>
      )}
    </>
  );
};

export default TextInput;
