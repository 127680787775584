import React from "react";
import "./HowWeHeader.css";
import Section from "../../../../components/Section/Section";
import { Trans } from "react-i18next";

function HowWeHeader() {
  return (
    <>
      <Section customStyle="how-we-do-header">
        <div className="text-center">
          <p>
            <Trans i18nKey="our_work.header_text" components={[<strong />]} />
            {/* EXALT meets your <strong>budget</strong>,<strong> timeline</strong>,
            and innovation wants, due to its
            <strong> commitment to quality</strong> and investment in human
            capital. */}
          </p>
        </div>
      </Section>
    </>
  );
}

export default HowWeHeader;
