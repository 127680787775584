import React from "react";
import "./Title.css";

const Title = ({ text, customStyle }) => {
  return (
    <div className="title-container">
      <div className={`title ${customStyle}`} tabIndex={0}>
        {text}
      </div>
      <div className="line" />
    </div>
  );
};

export default Title;
