import React, { useRef } from "react";
import { ReactComponent as ChevronIcon } from "../../assets/Domains/next.svg";
import "./PageNavbar.css";

const PageNavbar = ({ actions }) => {
  const ref = useRef(null);
  return (
    <React.Fragment>
      <div ref={ref} className="page-navbar-container">
        {actions.map((action, index) => {
          if (index === 0) {
            return (
              <span
                key={index}
                className={index === actions.length - 1 ? "active-link" : ""}
                role="button"
                onClick={action.onClick}
                onKeyDown={(e) => e.key === "Enter" && action?.onClick?.()}
                tabIndex={0}
              >
                {action.title}
              </span>
            );
          } else {
            return (
              <span key={index}>
                <span className="page-navbar-next-icon">
                  <ChevronIcon width="20" />
                </span>
                <span
                  className={index === actions.length - 1 ? "active-link" : ""}
                  role="button"
                  onClick={action?.onClick}
                  onKeyDown={(e) => e.key === "Enter" && action?.onClick?.()}
                  tabIndex={0}
                >
                  {action.title}
                </span>
              </span>
            );
          }
        })}
      </div>
      <div style={{ height: ref.current?.clientHeight ?? 64 }}></div>
    </React.Fragment>
  );
};
export default PageNavbar;
