import React, { useEffect, useRef } from "react";
import { scrollIntoView } from "seamless-scroll-polyfill";

import "./DetailsIcon.css";

function DetailsIconComponent(props) {
  const detailsIconRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (props.isHovered === true) {
        scrollIntoView(document.querySelector("#domains"), {
          behavior: "instant",
          block: "center",
          inline: "center",
        });
      }
    }, 5);
  }, [props.isHovered]);

  return (
    <>
      <div
        className={` ${props.isHovered ? "details-icon-hover" : ""}`}
        ref={detailsIconRef}
      >
        <div className="row detail-icon">
          <img
            className={`details-icon-img img-fluid"`}
            src={props.icon}
            alt="ui/ux"
          />
        </div>
      </div>
      {props.isHovered ? (
        <div className=" details-icon-title">
          <h2 style={{ textAlign: "left" }}>{props.title}</h2>
          <p>{props.description}</p>
        </div>
      ) : null}
    </>
  );
}

export default DetailsIconComponent;
