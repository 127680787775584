import React from "react";

import "./Details.css";

import UiUxIcon from "../../../../../assets/Domains/ux_icon.svg";
import WebServicesIcon from "../../../../../assets/Domains/web_services_icon.svg";
import MobileAppsIcon from "../../../../../assets/Domains/mobile_apps_icon.svg";
import DvIcon from "../../../../../assets/Domains/dv_icon.svg";
import ApiIcon from "../../../../../assets/Domains/api_icon.svg";
import CloudIcon from "../../../../../assets/Domains/cloud_icon.svg";
import QaIcon from "../../../../../assets/Domains/qa_icon.svg";
import DevOpsIcon from "../../../../../assets/Domains/devops_icon.svg";
import CloseIcon from "../../../../../assets/Domains/close.svg";
import FirmwareIcon from "../../../../../assets/Domains/firmware_icon.svg";
import DetailsIconComponent from "./Components/DetailsIcon/DetailsIcon";
import { useTranslation } from "react-i18next";
import DetailTextComponent from "./Components/DetailText/DetailText";

function DetailsComponent(props) {
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = React.useState(props.title);

  return (
    <>
      <div
        className="details"
        data-aos="zoom"
        data-aos-duration="600"
        data-aos-once="true"
      >
        <div className="horizontal-list">
          <div className="close-details">
            <img
              src={CloseIcon}
              alt="close"
              onClick={props.handelCloseDetails}
            />
          </div>

          <div className="row" style={{ width: "80vw", flexWrap: "nowrap" }}>
            <div
              className="col-sm details-list-item"
              onClick={() => {
                setIsHovered(
                  t("home.domains.User Experience & Graphic Design.title")
                );
              }}
            >
              <DetailsIconComponent
                icon={UiUxIcon}
                isHovered={
                  isHovered ===
                  t("home.domains.User Experience & Graphic Design.title")
                }
              />
            </div>
            <div
              className="col-sm details-list-item"
              onClick={() => {
                setIsHovered(t("home.domains.Web Development.title"));
              }}
            >
              <DetailsIconComponent
                icon={WebServicesIcon}
                isHovered={
                  isHovered === t("home.domains.Web Development.title")
                }
              />
            </div>

            <div
              className="col-sm details-list-item"
              onClick={() => {
                setIsHovered(t("home.domains.Mobile Apps.title"));
              }}
            >
              <DetailsIconComponent
                icon={MobileAppsIcon}
                isHovered={isHovered === t("home.domains.Mobile Apps.title")}
              />
            </div>

            <div
              className="col-sm details-list-item"
              onClick={() => {
                setIsHovered(t("home.domains.Design Verification.title"));
              }}
            >
              <DetailsIconComponent
                icon={DvIcon}
                isHovered={
                  isHovered === t("home.domains.Design Verification.title")
                }
              />
            </div>
            <div
              className="col-sm details-list-item"
              onClick={() => {
                setIsHovered(t("home.domains.API & Backend.title"));
              }}
            >
              <DetailsIconComponent
                icon={ApiIcon}
                isHovered={isHovered === t("home.domains.API & Backend.title")}
              />
            </div>
            <div
              className="col-sm details-list-item"
              onClick={() => {
                setIsHovered(t("home.domains.Cloud Solutions.title"));
              }}
            >
              <DetailsIconComponent
                icon={CloudIcon}
                isHovered={
                  isHovered === t("home.domains.Cloud Solutions.title")
                }
              />
            </div>
            <div
              className="col-sm details-list-item"
              onClick={() => {
                setIsHovered(t("home.domains.Quality Assurance.title"));
              }}
            >
              <DetailsIconComponent
                icon={QaIcon}
                isHovered={
                  isHovered === t("home.domains.Quality Assurance.title")
                }
              />
            </div>
            <div
              className="col-sm details-list-item"
              onClick={() => {
                setIsHovered(t("home.domains.DevOps.title"));
              }}
            >
              <DetailsIconComponent
                icon={DevOpsIcon}
                isHovered={isHovered === t("home.domains.DevOps.title")}
              />
            </div>
            <div
              className="col-sm details-list-item"
              onClick={() => {
                setIsHovered(t("home.domains.Firmware Development.title"));
              }}
            >
              <DetailsIconComponent
                icon={FirmwareIcon}
                isHovered={
                  isHovered === t("home.domains.Firmware Development.title")
                }
              />
            </div>
          </div>
        </div>
        <div className="row item-text">
          <DetailTextComponent domain={isHovered} />
        </div>
      </div>
    </>
  );
}

export default DetailsComponent;
