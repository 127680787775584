import React from "react";
import { THEME } from "../../constants/constants";
import "./TextArea.css";
import { MdError } from "react-icons/md";

const TextArea = ({
  name,
  placeholder,
  rows,
  customStyle,
  theme,
  value,
  onChange,
  error = false,
  ...otherProps
}) => {
  return (
    <div className="input-container">
      <textarea
        className={`text-area ${
          theme === THEME.DARK ? "dark-theme" : "light-theme"
        } ${customStyle} ${error && "error"}`}
        name={name}
        rows={rows ?? 3}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        {...otherProps}
      />
      {error && <MdError className="error-icon" />}
    </div>
  );
};

export default TextArea;
