import React from "react";
import { useTranslation } from "react-i18next";
import Section from "../Section/Section";
import { COMPANY_LINKS } from "../../constants/constants";

import "./CompanyInfo.css";
import { useNavigate } from "react-router-dom";
import { scrollIntoView, scroll } from "seamless-scroll-polyfill";

const CompanyLinkComponent = ({ site }) => {
  return (
    <a
      href={site.url}
      tabIndex={0}
      target="_blank"
      rel="noopener noreferrer"
      className="icon-container"
      aria-label={site.title}
    >
      {site.icon}
    </a>
  );
};

const ContactComponent = ({ title, number }) => {
  return (
    <div className="inner-cont-details" tabIndex={0}>
      <div className="inner-cont-details-title">{title}</div>
      <div className="inner-cont-details-value">{number}</div>
    </div>
  );
};

const CompanyAddressComponent = ({ address }) => {
  return (
    <div className="col-lg-4 cont">
      <div className="inner-cont">
        <div className="inner-cont-title" tabIndex={0}>
          {address.title}
        </div>
        <span tabIndex={0}>{address.location}</span>
        {address.contacts.map((contact) => (
          <ContactComponent
            key={contact.title}
            title={contact.title}
            number={contact.value}
          />
        ))}
      </div>
    </div>
  );
};

const CompanyInfo = (props) => {
  const { t } = useTranslation();
  const currentDate = new Date();
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/policy");
    setTimeout(() => {
      scrollIntoView(document.querySelector("#privacy-title"), {
        behavior: "instant",
        block: "center",
        inline: "center",
      });
    }, 100);
  };
  const currentYear = currentDate.getFullYear();
  const addresses = t("address");
  return (
    <Section id="company-info" customStyle="address-container">
      <div className="container company-location">
        <div className="row ">
          <div className="col-lg-4 cont">
            {COMPANY_LINKS.map((site) => (
              <CompanyLinkComponent key={site.title} site={site} />
            ))}
          </div>
          {addresses.map((address) => (
            <CompanyAddressComponent key={address.title} address={address} />
          ))}
        </div>
      </div>
      <div className="copyrights" tabIndex={0}>
        <p>
          {t("common.copyrights", { year: currentYear })} |{"  "}
          <a className="privacy-policy-text" onClick={handleClick}>
            {t("common.privacy_policy")}
          </a>
        </p>
      </div>
    </Section>
  );
};

export default CompanyInfo;
