import React from "react";
import "./WhyWorkWithUs.css";
import Section from "../../../../components/Section/Section";
import WhyWorkListItemComponent from "./WhyWorkListItem/WhyWorkListItem";

import { useTranslation } from "react-i18next";

function WhyWorkThisUs() {
  const { t } = useTranslation();
  return (
    <Section
      customStyle="why-work-this-us"
      title={t("our_work.why-work-with-us.title")}>
      <div className="col why-work-with-us-col">
        <WhyWorkListItemComponent
          title={t(
            "our_work.why-work-with-us.Complete Development Cycle.title"
          )}
          description={t(
            "our_work.why-work-with-us.Complete Development Cycle.description"
          )}
        />

        <WhyWorkListItemComponent
          title={t("our_work.why-work-with-us.Efficiency.title")}
          description={t("our_work.why-work-with-us.Efficiency.description")}
        />

        <WhyWorkListItemComponent
          title={t("our_work.why-work-with-us.Advanced Expertise.title")}
          description={t(
            "our_work.why-work-with-us.Advanced Expertise.description"
          )}
        />

        <WhyWorkListItemComponent
          title={t("our_work.why-work-with-us.Domain Knowledge.title")}
          description={t(
            "our_work.why-work-with-us.Domain Knowledge.description"
          )}
        />

        <WhyWorkListItemComponent
          title={t("our_work.why-work-with-us.Depth of Experience.title")}
          description={t(
            "our_work.why-work-with-us.Depth of Experience.description"
          )}
        />

        <WhyWorkListItemComponent
          title={t("our_work.why-work-with-us.Managerial Attention.title")}
          description={t(
            "our_work.why-work-with-us.Managerial Attention.description"
          )}
        />
      </div>
    </Section>
  );
}

export default WhyWorkThisUs;
