import React from "react";
import "./GridItem.css";

function GridItem(props) {
  return (
    <div className=" how-we-grid-item">
      <div className="row-sm">
        <div className="col how-we-grid-item">
          <div className="row-sm-3">
            <img className="how-we-grid-icon" src={props.img} alt="Icon" />
          </div>

          <div className="row">
            <div className="how-we-grid-item-text">
              <div className="how-we-grid-item-title">{props.title}</div>
              <div className="how-we-grid-item-description">
                {props.description}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GridItem;
