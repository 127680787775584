import React from "react";
import { THEME } from "../../constants/constants";
import "./Button.css";

const Button = ({
  onClick,
  title,
  customStyle,
  disabled,
  theme = THEME.LIGHT,
  outlined = false,
  ...otherProps
}) => {
  return (
    <button
      onClick={onClick}
      className={`button ${
        theme === THEME.DARK
          ? outlined
            ? "dark-outline-btn"
            : "dark-theme-btn"
          : outlined
          ? "light-outline-btn"
          : "light-theme-btn"
      } ${customStyle}`}
      {...otherProps}
      disabled={disabled}>
      {title}
    </button>
  );
};

export default Button;
