import React from "react";

import "./App.css";
import CompanyInfo from "./components/CompanyInfo/CompanyInfo";
import NavBar from "./components/Navbar/NavBar";
import Router from "./routes/Router";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  AOS.init();
  return (
    <div className="App">
      <NavBar />
      <Router />
      <CompanyInfo />
      <ToastContainer
        autoClose={3000}
        pauseOnFocusLoss={false}
        hideProgressBar={true}
      />
    </div>
  );
}

export default App;
