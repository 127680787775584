import axios from "axios";
import {
  ACTIVE_JOBS,
  BASE_URL,
  GET_IN_TOUCH,
  JOBS,
} from "../constants/constants";

const client = axios.create({
  baseURL: BASE_URL,
});

export const getInTouch = (body, callbacks) => {
  client
    .post(`${BASE_URL}${GET_IN_TOUCH}`, JSON.stringify(body), {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(callbacks.onSuccess)
    .catch(callbacks.onFailure);
};
export const fetchJobs = (callbacks) =>
  client
    .get(`${BASE_URL}${JOBS}${ACTIVE_JOBS}`)
    .then(callbacks.onSuccess)
    .catch(callbacks.onFailure);

export const fetchJobDetails = (jobId, callbacks) =>
  client
    .get(`${BASE_URL}${JOBS}${jobId}`)
    .then(callbacks.onSuccess)
    .catch(callbacks.onFailure);
