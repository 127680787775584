import {
  APPLY_JOB_ERRORS_INITIAL_STATE,
  ERRORS,
  GET_IN_TOUCH_ERRORS_INITIAL_STATE,
} from "../constants/constants";
import i18n from "../i18n/i18n";
import validator from "validator";

export const changeToBrowserLanguage = () => {
  if (navigator?.language?.includes("ar")) {
    i18n?.changeLanguage("ar");
    document.dir = "rtl";
  } else {
    i18n?.changeLanguage("en");
    document.dir = "ltr";
  }
};

export const isValidInput = (value) => {
  return value?.trim()?.length > 0;
};

export const isValidEmail = (email) => {
  return /\S+@\S+\.\S+/.test(email);
};

export const isValidNumber = (numberString) => {
  return !isNaN(Number(numberString));
};

export const isValidPhoneNumber = (phone) => {
  const isValidPhoneNumber = validator.isMobilePhone(phone);
  return isValidPhoneNumber;
};

export const validateGetInTouchForm = (formData) => {
  const errors = { ...GET_IN_TOUCH_ERRORS_INITIAL_STATE };
  errors.name = !isValidInput(formData.name) && ERRORS.REQUIRED;
  errors.email = !isValidEmail(formData.email) && ERRORS.INVALID_EMAIL;
  errors.message = !isValidInput(formData.message) && ERRORS.REQUIRED;
  errors.privacyAgreed = !formData.privacyAgreed && ERRORS.REQUIRED;

  let isValidFormData =
    errors.name ||
    errors.email ||
    errors.phone ||
    errors.message ||
    errors.privacyAgreed;
  return { errors, isValidFormData: !isValidFormData };
};

export const validateApplyNowForm = (
  personalInfo,
  experienceInfo,
  univeristyInfo,
  scoresInfo,
  resume
) => {
  const errors = { ...APPLY_JOB_ERRORS_INITIAL_STATE };

  let isValidFormData = false;

  errors.personalInfo.fname =
    !isValidInput(personalInfo.fname) && ERRORS.REQUIRED;
  errors.personalInfo.lname =
    !isValidInput(personalInfo.lname) && ERRORS.REQUIRED;
  errors.personalInfo.email =
    !isValidEmail(personalInfo.email) && ERRORS.INVALID_EMAIL;
  errors.personalInfo.phone =
    !isValidPhoneNumber(personalInfo.phone) && ERRORS.INVALID_PHONE;

  errors.resume = !resume && ERRORS.REQUIRED;

  isValidFormData =
    errors.personalInfo.fname ||
    errors.personalInfo.fname ||
    errors.personalInfo.email ||
    errors.personalInfo.phone ||
    errors.resume;

  errors.universityInfo.name =
    !isValidInput(univeristyInfo.name) && ERRORS.REQUIRED;
  errors.universityInfo.discipline =
    !isValidInput(univeristyInfo.discipline) && ERRORS.REQUIRED;
  errors.universityInfo.gpa =
    (!isValidInput(univeristyInfo.gpa) && ERRORS.REQUIRED) ||
    (!isValidNumber(univeristyInfo.gpa) && ERRORS.INVALID_VALUE);

  isValidFormData =
    isValidFormData ||
    errors.universityInfo.name ||
    errors.universityInfo.discipline ||
    errors.universityInfo.gpa;

  if (experienceInfo === "Fresh") {
    errors.scores.highSchool =
      (!isValidInput(scoresInfo.highSchool) && ERRORS.REQUIRED) ||
      (!isValidNumber(scoresInfo.highSchool) && ERRORS.INVALID_VALUE);
    errors.scores.dataStructure =
      (!isValidInput(scoresInfo.dataStructure) && ERRORS.REQUIRED) ||
      (!isValidNumber(scoresInfo.dataStructure) && ERRORS.INVALID_VALUE);
    errors.scores.dataBase =
      (!isValidInput(scoresInfo.dataBase) && ERRORS.REQUIRED) ||
      (!isValidNumber(scoresInfo.dataBase) && ERRORS.INVALID_VALUE);
    errors.scores.programmingLanguage =
      (!isValidInput(scoresInfo.programmingLanguage) && ERRORS.REQUIRED) ||
      (!isValidNumber(scoresInfo.programmingLanguage) && ERRORS.INVALID_VALUE);
    isValidFormData =
      isValidFormData ||
      errors.scores.highSchool ||
      errors.scores.dataStructure ||
      errors.scores.dataBase ||
      errors.scores.programmingLanguage;
  }
  return { errors, isValidFormData: !isValidFormData };
};
