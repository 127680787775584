import React, { useState, useEffect, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { ReactComponent as ExaltLogo } from "../../assets/logo.svg";
import { ReactComponent as MenuIcon } from "../../assets/menu_blue.svg";
import { ReactComponent as CloseIcon } from "../../assets/close.svg";
import useClickOutside from "../../hooks/useClickOutside";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import "./NavBar.css";

const NavBar = () => {
  const { width, height } = useWindowDimensions();
  const location = useLocation();
  const { t } = useTranslation();
  const ref = useRef(null);
  const [navbarStyle, setNavbarStyle] = useState({
    backgroundColor: "rgba(225, 236, 242, 0)",
    isSticky: false,
  });
  const [isExpanded, setIsExpanded] = useState(false);
  useClickOutside(ref, () => setIsExpanded(false));

  const handleClickLink = useCallback(() => {
    window.scrollTo(0, 0);
    setIsExpanded(false);
  }, []);

  const getNavbarClassName = useCallback(() => {
    return `navbar-container ${
      !navbarStyle.isSticky && location.pathname === "/home"
        ? "fixed"
        : "sticky"
    }`;
  }, [navbarStyle, location]);

  const getNavbarStyle = useCallback(() => {
    return location.pathname === "/home"
      ? { backgroundColor: navbarStyle.backgroundColor }
      : {};
  }, [navbarStyle, location]);

  const getNavLinkClassName = useCallback((navData) => {
    return navData.isActive ? "navbar-link active" : "navbar-link";
  }, []);

  const handleScroll = useCallback(() => {
    if (window.scrollY >= height) {
      setNavbarStyle({
        backgroundColor: `rgba(225, 236, 242, ${window.scrollY / height})`,
        isSticky: true,
      });
    } else {
      setNavbarStyle({
        backgroundColor: `rgba(225, 236, 242, ${window.scrollY / height})`,
        isSticky: false,
      });
    }
  }, [height]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
    <nav
      id="navbar"
      className={getNavbarClassName()}
      style={getNavbarStyle()}
      ref={ref}
    >
      <NavLink onClick={() => window.scrollTo(0, 0)} to="/home" role="link">
        <ExaltLogo />
      </NavLink>
      <div
        className="collapse-btn"
        tabIndex={0}
        onClick={() => setIsExpanded((state) => !state)}
      >
        {isExpanded ? <CloseIcon /> : <MenuIcon />}
      </div>
      <div className={`nav-links-container ${isExpanded && "show"}`}>
        {(width <= 992 || location.pathname !== "/home") && (
          <NavLink
            onClick={handleClickLink}
            className={getNavLinkClassName}
            to="/home"
            role="link"
          >
            {t("home.title")}
          </NavLink>
        )}
        <NavLink
          onClick={handleClickLink}
          className={getNavLinkClassName}
          to="/about-us"
          role="link"
        >
          {t("about_us.title")}
        </NavLink>
        <NavLink
          onClick={handleClickLink}
          className={getNavLinkClassName}
          to="/our-work"
          role="link"
        >
          {t("our_work.title")}
        </NavLink>
        {/* <NavLink
          onClick={handleClickLink}
          className={getNavLinkClassName}
          to="/jobs"
          role="link"
        >
          {t("jobs.title")}
        </NavLink> */}
        <HashLink
          className="navbar-link"
          to={
            location.pathname.includes("home")
              ? `#${t("get_in_touch.title")}`
              : location.pathname.includes("jobs")
              ? `/home#${t("get_in_touch.title")}`
              : `#`
          }
          role="link"
          onClick={() => {
            setIsExpanded(false);
            if (
              !location.pathname.includes("home") &&
              !location.pathname.includes("jobs")
            ) {
              window.scrollTo(
                0,
                document.getElementById(t("get_in_touch.title")).offsetTop -
                  ref.current.clientHeight
              );
            }
          }}
        >
          {t("get_in_touch.title")}
        </HashLink>
      </div>
    </nav>
  );
};

export default NavBar;
