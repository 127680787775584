import React from "react";

import "./Domains.css";

import MasterComponent from "./Master/Master";
import DetailsComponent from "./Details/Details";

import Section from "../../../../components/Section/Section";

function DomainsComponent() {
  const [title, setTitle] = React.useState();

  const handleDetailsClick = (title) => {
    setTitle(title);
  };

  const handelCloseDetails = () => {
    setTitle(null);
  };

  return (
    <Section id="domains" customStyle="domains">
      <div className="content">
        {title ? (
          <DetailsComponent
            handelCloseDetails={handelCloseDetails}
            title={title}
          />
        ) : (
          <MasterComponent handleDetailsClick={handleDetailsClick} />
        )}
      </div>
    </Section>
  );
}

export default DomainsComponent;
