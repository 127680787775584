import React from "react";
import Page from "../../components/Page/Page";
import ClientsComponent from "./components/Clients/Clients";
import DomainsComponent from "./components/Domains/Domains";
import Introduction from "./components/Introduction/Introduction";
import "./Home.css";

const Home = () => {
  return (
    <Page>
      <Introduction />
      <DomainsComponent />
      <ClientsComponent />
    </Page>
  );
};

export default Home;
