import React, { useCallback, useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PageNavbar from "../../components/PageNavbar/PageNavbar";
import Page from "../../components/Page/Page";
import Popup from "../../components/Popup/Popup";
import { APPLY_JOB_STATE } from "../../constants/constants";
import "./Jobs.css";

const Jobs = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { id } = useParams();
  const job = useSelector((state) =>
    state.detailedJobsReducer.jobs.find((job) => job.id.toString() === id)
  );

  const [popupConfig, setPopupConfig] = useState({
    visible: false,
    state: APPLY_JOB_STATE.SUCCESS,
  });

  const getNavigationHeader = useCallback(
    (str) => {
      switch (str) {
        case "jobs":
          return t("jobs.header_title");
        case "apply-now":
          return t("jobs.apply");
        default:
          return job?.title;
      }
    },
    [job?.title, t]
  );
  const handleClickNavigationHeader = useCallback(
    (links, index) => {
      const prevRouteIndex = -(links.length - index - 1);
      if (prevRouteIndex !== 0) {
        navigate(-(links.length - index - 1));
      }
    },
    [navigate]
  );
  const getActions = useCallback(() => {
    const links = location?.pathname?.substring(1).split("/");
    const actions = links.map((link, index) => ({
      title: getNavigationHeader(link),
      onClick: () => handleClickNavigationHeader(links, index),
    }));
    return actions;
  }, [location, getNavigationHeader, handleClickNavigationHeader]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (location?.state?.success) {
      setPopupConfig({
        visible: true,
        state: APPLY_JOB_STATE.SUCCESS,
      });

      // clear location state
      navigate(location.pathname, { state: null });
    }
  }, [location]);

  return (
    <Page>
      <PageNavbar actions={getActions()} />
      <Outlet />
      <Popup
        visible={popupConfig.visible}
        {...popupConfig.state}
        onClick={() => {
          setPopupConfig({
            visible: false,
            state: APPLY_JOB_STATE.SUCCESS,
          });
        }}
      />
    </Page>
  );
};

export default Jobs;
