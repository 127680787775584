import React from "react";
import Title from "../Title/Title";
import "./Section.css";

const Section = ({
  customStyle,
  titleStyle,
  title,
  children,
  id,
  style = {},
}) => {
  return (
    <div id={id} className={`section ${customStyle}`} style={style}>
      {title && <Title customStyle={titleStyle} text={title} />}
      {children}
    </div>
  );
};

export default Section;
